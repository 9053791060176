<template>
  <span>{{ slotText.substring(0, length) }}{{ slotText.length > length ? '...' : '' }}</span>
</template>

<script setup>
import { defineProps } from 'vue'

import useSlotText from '@/hooks/useSlotText'

defineProps({
  length: Number,
})

const slotText = useSlotText()
</script>
