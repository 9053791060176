<template>
  <div class="card my-4">
    <h2 class="w-full title flex justify-between">
      <span>Jouw planning &mdash; {{ capitalize(dateWritten(date)) }}</span>
      <TextBelgiumTime v-if="!hideTime" class="font-normal text-sm" aria-hidden="true" />
    </h2>

    <div class="flex flex-col gap-2 md:flex-row justify-between items-center w-full pb-4">
      <div class="flex-1 hidden md:block">
        <span v-if="reservaties?.beschikbaar" class="flex flex-row gap-1">
          <span>{{ afspraken?.list.length }} {{ afspraken?.list.length === 1 ? 'Afspraak' : 'Afspraken' }}</span>
          <span>-</span>
          <span>{{ reservaties?.list.length }} {{ reservaties?.list.length === 1 ? 'Rit' : 'Ritten' }}</span>
        </span>
        <span v-else>{{ isAfterNow ? 'Nog niet beschikbaar' : 'Niet meer beschikbaar' }}</span>
      </div>
      <UIInputDatePicker
        v-show="!hideFilters"
        v-model:date="date"
        class="flex-1 text-center"
        planning
        buttons
      />
      <div v-if="store.state.profile" class="flex-1 text-right">
        <ButtonSlaapStatus v-model="store.state.profile.is_slapend" />
      </div>
    </div>
    <TextChauffeurSlaapt v-if="store.state.profile?.is_slapend" />
    <TextNietBeschikbaar v-else-if="!reservaties?.beschikbaar" :future="isAfterNow" />
    <TablePlanning v-else :reservaties="reservaties?.list" :afspraken="afspraken?.list" />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, defineProps, ref } from 'vue'
import { useStore } from 'vuex'

import { dateString, dateWritten } from '@/functions/formatDate'
import { capitalize } from '@/functions/formatText'
import useGetApi from '@/hooks/useGetApi'
import useRouteQueryDate from '@/hooks/useRouteQueryDate'

const props = defineProps({
  onlyToday: Boolean,
  hideFilters: Boolean,
  hideTime: Boolean,
})
const TablePlanning = defineAsyncComponent(() => import('@/components/Table/Planning/Index.vue'))
const ButtonSlaapStatus = defineAsyncComponent(() => import('@/components/UI/Button/SlaapStatus.vue'))
const UIInputDatePicker = defineAsyncComponent(() => import('@/components/UI/Input/DatePicker.vue'))
const TextChauffeurSlaapt = defineAsyncComponent(() => import('@/components/UI/Text/ChauffeurSlaapt.vue'))
const TextNietBeschikbaar = defineAsyncComponent(() => import('@/components/UI/Text/NietBeschikbaar.vue'))
const TextBelgiumTime = defineAsyncComponent(() => import('@/components/UI/Text/BelgiumTime.vue'))

const store = useStore()

const date = props.onlyToday ? ref(dateString(new Date())) : useRouteQueryDate('date')
const apiData = computed(() => ({ date: date.value }))

const isAfterNow = computed(() => new Date(date.value).getTime() > Date.now())

const { data: reservaties } = useGetApi('/api/dashboard/planning/list', apiData, { watch: true })
const { data: afspraken } = useGetApi('AFSPRAAK_LIST', apiData, { watch: true })
</script>
