<template>
  <span class="inline-flex gap-1">
    <button
      :disabled="!opmerking"
      class="whitespace-nowrap btn smaller text-sm pt-0 pb-0 py-0"
      :class="{ 'bg-gray-50 text-gray-400 cursor-not-allowed': !opmerking, 'yellow cursor-pointer': opmerking }"
      @click="showOpmerking"
    >
      <i class="fas fa-comment"></i>
      <span v-if="!opmerking">Geen opmerking</span>
      <TextTruncate v-else-if="previewLength > 0" :length="Number(previewLength)">{{ String(opmerking || '') }}</TextTruncate>
      <span v-else>Opmerking</span>
    </button>
    <i v-if="edit && !disabled" class="fas fa-edit text-yellow-700 cursor-pointer" @click="onEdit"></i>
  </span>
</template>

<script setup>
// import useNotification from '@/hooks/useNotification'
import TextTruncate from '@/components/UI/Text/Truncate.vue'

const props = defineProps({
  opmerking: String,
  edit: Boolean,
  disabled: Boolean,
  previewLength: [Number, String],
})

const emit = defineEmits(['edit'])

const onEdit = event => {
  emit('edit', event)
}

const showOpmerking = () => {
  if (!props.opmerking?.length) return
  window.alert(props.opmerking)
  // useNotification(`<b>Opmerking:</b><br>${props.opmerking.split('\n').join('<br>')}`, 'info')
}
</script>
