<template>
  <UILoading v-if="loading" />
  <div v-else class="flex flex-col gap-6 max-w-5xl mx-auto">
    <h1 class="text-xl sm:text-2xl font-semibold text-gray-900">
      Drivers Champions League &mdash; Win een reis
    </h1>

    <div class="flex flex-col gap-4" :class="{ 'md:flex-row': !zoomed }">
      <div class="card bg-white flex flex-col justify-center">
        <p class="mb-4">
          Om jullie inzet en harde werk te belonen, willen we graag een speciale reis naar Spanje organiseren voor de 3 meest verdienstelijke chauffeurs die hebben bijgedragen aan de groei en het succes van ons bedrijf. En dat is nog niet alles! Als we onze bedrijfsdoelstellingen dit jaar halen, zullen we dit aantal verdubbelen en zullen maar liefst 6 van onze chauffeurs op reis kunnen gaan.
        </p>
        <p class="mb-4">
          Ons bedrijf bevindt zich momenteel in een mooie groeifase en we hebben ambitieuze maar haalbare doelstellingen gesteld om deze groei te ondersteunen. Jullie bijdrage is van onschatbare waarde en samen kunnen we deze doelen bereiken.<br>
        </p>
        <p class="">
          Om de meest verdienstelijke chauffeurs te selecteren, zullen we een beoordeling uitvoeren op basis van verschillende criteria die essentieel zijn voor onze bedrijfsvoering. Hierbij is het belangrijk dat de evaluatie eerlijk en transparant verloopt, zodat iedereen begrijpt hoe de selectie tot stand komt.
        </p>
      </div>
      <img
        src="/cdn/public/Drivers-Champions-League.jpg"
        alt="Drivers Champions League - Win een reis"
        class="mx-auto transition-all"
        :style="zoomed ? 'max-width: 100%' : 'max-width: 320px'"
        @click="zoomed = !zoomed"
      >
    </div>

    <h2>
      Wil jij deze reis winnen? Let dan op deze beoordelingscriteria en hun bijbehorende gewichten:
    </h2>

    <div class="card bg-white">
      <ul class="flex flex-col gap-6">
        <li>
          <h3 class="mb-1">
            Klantenservice: 20%
          </h3>
          <ul class="list-disc ml-6">
            <li>Frequentie van positieve feedback en reviews van klanten.</li>
            <li>Klanten uitleggen waar we hen opwachten bij de terugrit.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Punctualiteit: 20%
          </h3>
          <ul class="list-disc ml-6">
            <li>Hoe vaak chauffeurs op tijd bij de klant en op de bestemming aankomen.</li>
            <li>Tijdig komen afrekenen.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Rittenvoltooiing: 15%
          </h3>
          <ul class="list-disc ml-6">
            <li>Het aantal ritten dat zonder incidenten of klachten voltooid wordt.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Veilig rijgedrag: 15%
          </h3>
          <ul class="list-disc ml-6">
            <li>Analyse van rijgedrag op basis van incidenten en verkeersovertredingen.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Flexibiliteit en beschikbaarheid: 10%
          </h3>
          <ul class="list-disc ml-6">
            <li>Hoe vaak chauffeurs beschikbaar zijn voor ritten, tijdens drukke tijden of onverwachte situaties.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Ziekteverzuim: 8%
          </h3>
          <ul class="list-disc ml-6">
            <li>Aantal ziektedagen en communicatie hierover, evenals de redenen voor verzuim.</li>
          </ul>
        </li>
        <li>
          <h3 class="mb-1">
            Loyaliteit, inzet, en extra verantwoordelijkheden: 12%
          </h3>
          <ul class="list-disc ml-6">
            <li>Consistentie en duur van het dienstverband.</li>
            <li>Samenwerking met collega's.</li>
            <li>Bereidheid om extra taken op te nemen.</li>
            <li>Onderhoud en respect voor het wagenpark (vanbinnen en vanbuiten).</li>
            <li>Dragen van de kledij van het bedrijf.</li>
            <li>Correct invullen van de app (werkuren, contracten laten tekenen, doorgeven wanneer de auto op onderhoud moet of bij technische problemen).</li>
            <li>Anciënniteit.</li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="card bg-white">
      <p class="text-lg font-bold">
        We hopen dat deze transparante beoordelingsmethode motiveert en inspireert om je beste beentje voor te zetten. Bedankt voor jullie harde werk en toewijding. Samen kunnen we blijven groeien en een fijne werkplek voor iedereen creëren.
      </p>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

import UILoading from '@/components/UI/Loading.vue'

const zoomed = ref(false)
const loading = ref(true)

const axios = inject('axios')
onMounted(() => {
  axios.post('/api/shared/viewed/save', { item: 'CHAUFFEUR_CHAMPIONS_LEAGUE' }).then(() => {
    loading.value = false
  })
})
</script>
