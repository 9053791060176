<template>
  <div class="flex flex-col gap-4 w-full max-w-7xl">
    <div class="card">
      <h2>Wagen</h2>
      <UISelectWagen
        :key="form.wagen_id"
        v-model="form.wagen_id"
        class="w-full max-w-sm"
        active
      />
    </div>

    <div class="card">
      <h2>Herstellingen</h2>

      <div class="grid sm:grid-rows-6 md:grid-rows-6 lg:grid-rows-5 gap-4 sm:grid-flow-col">
        <UICheckbox
          v-for="(item, index) in HERSTELLINGEN"
          :key="index"
          v-model="form.HERSTELLINGEN[index]"
          :label="item"
        />
      </div>
    </div>

    <div class="card">
      <h2>Carroserie</h2>

      <div class="grid sm:grid-rows-6 md:grid-rows-6 lg:grid-rows-5 gap-4 sm:grid-flow-col">
        <UICheckbox
          v-for="(item, index) in CARROSERIE"
          :key="index"
          v-model="form.CARROSERIE[index]"
          :label="item"
        />
      </div>
    </div>

    <div class="card">
      <h2>Andere werken / herstellingen / extra info</h2>
      <UIInput
        v-model="form.opmerking"
        textarea
        class="w-full max-w-3xl"
        rows="6"
        placeholder="Uitleg of opmerking ..."
      />
    </div>

    <button
      type="submit"
      :disabled="isDisabled"
      class="btn success w-32"
      @click.prevent.stop="handleSubmit()"
    >
      Aanvragen
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useVModel } from '@vueuse/core'

import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UIInput from '@/components/UI/Input/Input'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'

import useApi from '@/hooks/useApi'

const props = defineProps({
  modelValue: {
    type: Object,
  },
})

const emit = defineEmits(['update:modelValue'])

const GENERATE_DEFAULT_FORM = () => ({
  wagen_id: null,
  HERSTELLINGEN: {},
  CARROSERIE: {},
  opmerking: null,
})

const api = useApi()
const form = useVModel(props, 'modelValue', emit, {
  deep: true,
  passive: true,
})

const HERSTELLINGEN = [
  'Onderhoud lampje brand',
  'Motor lampje brand',
  'Ander lampje brand',
  'Remmen',
  'Airco',
  'Sleutel batterij',
  'Sleutel werkt niet',
  'Lamp(en) (Vooraan)',
  'Lamp(en) (Achteraan)',
  'Ruitenwisser(s) (Vooraan)',
  'Ruitenwisser(s) (Achteraan)',
  'Banden',
  'Reservewiel',
  'Vloeistof verlies',
  'Uitrusting',
  'Andere',
]

const CARROSERIE = [
  'Bumper (Vooraan)',
  'Bumper (Achteraan)',
  'Motorkap',
  'Voorruit',
  'Spiegel (Links)',
  'Spiegel (Rechts)',
  'Deur (Vooraan Links)',
  'Deur (Vooraan Rechts)',
  'Deur (Achteraan Links)',
  'Deur (Achteraan Rechts)',
  'Kofferdeksel',
  'Velg(en)',
  'Andere',
]

form.value = {
  ...GENERATE_DEFAULT_FORM(),
  ...form.value,
}

const isDisabled = computed(() => api.loading.value || !form.value.wagen_id || !(form.value.HERSTELLINGEN || form.value.CARROSERIE || null))

const postData = computed(() => ({
  ...GENERATE_DEFAULT_FORM(),
  wagen_id: form.value.wagen_id > 0 ? form.value.wagen_id : null,
  HERSTELLINGEN: Object.entries(form.value.HERSTELLINGEN).reduce((result, [index, selected]) => {
    if (selected) result.push(`‣ ${HERSTELLINGEN[index]}`)
    return result
  }, []).join('\n'),
  CARROSERIE: Object.entries(form.value.CARROSERIE).reduce((result, [index, selected]) => {
    if (selected) result.push(`‣ ${CARROSERIE[index]}`)
    return result
  }, []).join('\n'),
  opmerking: form.value.opmerking?.length ? form.value.opmerking : null,
}))

const handleSubmit = () => {
  api('WAGEN_ONDERHOUD', postData).then(() => {
    form.value = GENERATE_DEFAULT_FORM()
  })
}
</script>
